var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "knowledge-base-article" } }, [
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-2/5 lg:w-1/4" },
        [
          _c("vx-card", { attrs: { title: "Related Questions" } }, [
            _c(
              "ul",
              { staticClass: "bordered-items" },
              _vm._l(_vm.relatedQuestions, function (que) {
                return _c(
                  "li",
                  { key: que.id, staticClass: "py-2" },
                  [
                    _c("router-link", { attrs: { to: que.answerUrl } }, [
                      _vm._v(_vm._s(que.question)),
                    ]),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-3/5 lg:w-3/4 mt-12 md:mt-0" },
        [
          _c(
            "vx-card",
            [
              _c("div", { staticClass: "article-title mb-6" }, [
                _c("h1", [_vm._v(_vm._s(_vm.article.title))]),
                _c("p", { staticClass: "mt-1" }, [
                  _vm._v("Last updated on "),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm._f("date")(_vm.article.lastUpdated, true))
                    ),
                  ]),
                ]),
              ]),
              _c("p", [
                _vm._v(
                  "Pastry jelly chocolate bar caramels fruitcake gummies marshmallow lemon drops. Powder cupcake topping muffin carrot cake croissant soufflé sugar plum sweet roll. Cotton candy ice cream gummies biscuit bonbon biscuit. Icing pastry bonbon. Sweet roll chocolate cake liquorice jelly beans caramels jelly cookie caramels. Pastry candy canes cake powder lollipop tootsie roll sugar plum. Cake cotton candy dragée danish. Muffin croissant sweet roll candy wafer marzipan cheesecake. Carrot cake toffee gummi bears gingerbread donut biscuit. Donut chupa chups oat cake cheesecake apple pie gummies marzipan icing cake. Macaroon jelly beans gummi bears carrot cake tiramisu liquorice. Sweet tootsie roll cookie marzipan brownie icing cookie jelly tart. Lollipop cookie tootsie roll candy canes."
                ),
              ]),
              _c("img", {
                staticClass: "responsive my-3 rounded",
                attrs: { src: _vm.article.img, alt: "article-img" },
              }),
              _c("p", { staticClass: "mb-6" }, [
                _vm._v(
                  "Candy canes oat cake biscuit halvah ice cream. Marshmallow icing topping toffee caramels dessert carrot cake. Liquorice soufflé brownie sugar plum dessert cotton candy. Cupcake halvah topping oat cake soufflé pastry dragée pudding cotton candy."
                ),
              ]),
              _c("h5", { staticClass: "mb-2" }, [_vm._v("Topics:")]),
              _c("vx-list", { attrs: { list: _vm.article.topics } }),
              _c("p", { staticClass: "mt-4" }, [
                _vm._v(
                  "Chocolate cake powder I love jelly beans lemon drops candy fruitcake. Sesame snaps sugar plum chocolate candy canes muffin. Wafer pastry topping croissant pudding dessert I love. Bonbon apple pie fruitcake candy canes I love. Lollipop sweet gingerbread I love I love dessert. I love halvah marshmallow pie jelly beans macaroon candy. Bonbon ice cream lollipop pie fruitcake oat cake. Topping marshmallow I love sesame snaps dragée. I love sesame snaps jelly. Chocolate sesame snaps jelly I love I love powder jelly-o."
                ),
              ]),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  { staticClass: "flex items-center justify-between" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "flex items-center",
                        attrs: {
                          to: "/pages/knowledge-base/category/question",
                        },
                      },
                      [
                        _c("feather-icon", {
                          staticClass: "mr-2",
                          attrs: {
                            icon: _vm.$vs.rtl
                              ? "ChevronsRightIcon"
                              : "ChevronsLeftIcon",
                            svgClasses: "h-4 w-4",
                          },
                        }),
                        _vm._v(" Previous Article"),
                      ],
                      1
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "flex items-center",
                        attrs: {
                          to: "/pages/knowledge-base/category/question",
                        },
                      },
                      [
                        _vm._v("Next Article "),
                        _c("feather-icon", {
                          staticClass: "ml-2",
                          attrs: {
                            icon: _vm.$vs.rtl
                              ? "ChevronsLeftIcon"
                              : "ChevronsRightIcon",
                            svgClasses: "h-4 w-4",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }